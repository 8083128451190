// i18next-extract-mark-ns-start prestashop-payment-gateway
import {Content} from 'components/Content';
import {List} from 'components/List';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';

import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Partners} from 'components/Partners';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import prestashop_monei from 'images/prestashop_monei.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';
import {AnchorLink} from 'components/AnchorLink';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="prestashop-payment-gateway"
        title={t('The Best Payment Gateway for PrestaShop')}
        description={t(
          'Looking for the best payment gateway for PrestaShop? Try MONEI and enjoy dynamic pricing, more payment methods, and quick and easy integration. Join now »'
        )}
        bodyAttributes={{class: 'selling-page'}}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Increase online conversions with the best PrestaShop payment gateway</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                You chose PrestaShop as your e-commerce platform because it lets you manage and
                scale your online business. But accepting more payment methods is a crucial part of
                your growth strategy. That’s why it’s important to integrate your store with the
                best PrestaShop payment gateway.
              </Trans>
              <Trans parent="p">
                That’s where we come in. Our advanced e-commerce payment gateway lets you accept all
                major, alternative, and local payment methods from a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={prestashop_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>
                  Accept more payment methods and increase sales with a PrestaShop payment gateway
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting more{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                helps you reach more people, boost customer satisfaction, and sell more. That’s why
                we built a PrestaShop payment gateway integration that lets you configure and manage
                all payment methods from one dashboard. Increase your conversion rate by offering
                customers their preferred payment options.
              </Trans>
              <List>
                <Trans parent="li">
                  <InternalPageLink slug="visa-mastercard-for-prestashop">
                    Credit cards
                  </InternalPageLink>{' '}
                  - Visa, Mastercard, and more
                </Trans>
                <Trans parent="li">
                  Digital wallets -{' '}
                  <InternalPageLink slug="paypal-for-prestashop">PayPal</InternalPageLink>,{' '}
                  <InternalPageLink slug="apple-pay-for-prestashop">Apple Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="google-pay-for-prestashop">Google Pay</InternalPageLink>,
                  and{' '}
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  Local payment methods -{' '}
                  <InternalPageLink slug="bizum-for-prestashop">Bizum</InternalPageLink>, and{' '}
                  <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>{' '}
                  (Portugal)
                </Trans>
                <Trans parent="li">
                  Direct Debit -{' '}
                  <InternalPageLink slug="payment-methods/sepa-direct-debit">SEPA</InternalPageLink>{' '}
                  and{' '}
                  <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>
                </Trans>
                <Trans parent="li">
                  <InternalPageLink slug="features/recurring-payments">
                    Subscription
                  </InternalPageLink>{' '}
                  payments
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Build trust by customizing your PrestaShop checkout page</Trans>
              </SectionHeader>
              <Trans parent="p">
                <AnchorLink href="https://support.monei.com/hc/en-us/categories/360003383258-Customization">
                  Customize
                </AnchorLink>{' '}
                your PrestaShop checkout page using the{' '}
                <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/">
                  Hosted Payment Page
                </AnchorLink>{' '}
                integration. It’s fully responsive and mobile ready. Add your logo, brand colors,
                and website URL to build trust, boost brand awareness, and reduce cart abandonement.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Spend less on transaction fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Our dynamic pricing model means as you sell more your transaction fees will decrease
                in real-time. Choose a flexible{' '}
                <AnchorLink href="https://monei.com/">payment gateway</AnchorLink> that grows with
                your business.
              </Trans>
              <Trans parent="p">
                <InternalPageLink slug="pricing">View pricing</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section textAlign="center">
          <div>
            <Content>
              <SectionHeader underline tagName="h3">
                <Trans>Get started with your PrestaShop payment gateway integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Use our PrestaShop payment gateway integration to start accepting more payment
                methods today.
              </Trans>
              <Trans parent="p">
                Once you’ve{' '}
                <AnchorLink href="https://dashboard.monei.com/">
                  created your MONEI account
                </AnchorLink>
                , all you have to do is add a new payment module to your PrestaShop back office, and
                configure it using our{' '}
                <AnchorLink href="https://docs.monei.com/api/#tag/Payments">
                  Payments API
                </AnchorLink>
                . Then activate PrestaShop payment methods and configure them in your MONEI
                dashboard to start accepting payments.
              </Trans>
            </Content>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "prestashop-payment-gateway"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
